import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LandingPageAppBar from "../components/LandingPageAppBar";
import { useHistory } from "react-router-dom";
import { Modal, Paper } from "@material-ui/core";
import successIcon from "../assets/images/success-icon.png";
import { Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { registerFreeTrial, onPaypalSuccess } from "../actions/payment";
import { PayPalButtons } from "@paypal/react-paypal-js";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { getPlanDetails } from "../actions/payment";
import MembershipCard from "../components/reusableComponents/MembershipCard";
import Android from "../assets/images/Android.png";
import apple from "../assets/images/apple.png";
import google from "../assets/images/google.png";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
      overflow: "hidden",
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "30px",
    color: theme.palette.primary.main,
    padding: "0 20%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw",
      lineHeight: "50px",
      padding: "0 10%",
    },
    textAlign: "center",
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.primary.main,
    lineHeight: "130%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "120%",
      lineHeight: "200%",
    },
    textAlign: "center",
  },
  membershipCardContainer: {
    width: "100vw",
    alignItems: "center",

    justifyContent: "space-evenly",
  },
  membershipCard: {
    alignItems: "center",
  },
  successModal: {
    width: "50%",
    margin: "auto",
    marginTop: "10%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  successPaper: {
    padding: "5% 20%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5%",
    },
  },
  button: {
    width: "150px",
    margin: 5,
  },
  button1: {
    width: "150px",
    margin: 4,
    height: 47,
  },
  ButtonView: {
    alignSelf: "center",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30%",
    paddingRight: "30%",
  },
}));

export default function StaticPlanScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [successModal, setSuccessModal] = React.useState(false);
  const dispatch = useDispatch();
  const [year, setYear] = React.useState(true);
  const [months, setMonths] = React.useState(false);
  const [months1, setMonths1] = React.useState(false);
  const [planData, setPlanData] = React.useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");

  const newUserPlans = [
    {
      title: "3 Months",
      // subheader: 'Most popular',
      price: "150",
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
    {
      title: "1 Year",
      // subheader: 'Most popular',
      price: "480",
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
  ];

  return (
    <React.Fragment>
      <LandingPageAppBar registration={true} />
      <CssBaseline />
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <CardActions style={{ justifyContent: "center", flexDirection: "row" }}>
          {newUserPlans.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={5}
              style={{ marginRight: "5px" }}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      / {tier.title}
                    </Typography>
                  </div>
                  {tier.title == "1 Year" && (
                    <Typography variant="h6" color="textSecondary">
                      Subscribe to Annual Premium Plan with 20% Discount
                    </Typography>
                  )}
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  {tier.title == "3 Months" ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() =>
                        window.open("https://buy.stripe.com/cN26qw12Fbh4f04148")
                      }
                    >
                      {tier.buttonText}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() =>
                        window.open("https://buy.stripe.com/6oEg166mZbh47xCdQT")
                      }
                    >
                      {tier.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </CardActions>
      </Container>
    </React.Fragment>
  );
}
