import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import RegistrationPage from "../pages/RegistrationPage";
import RegistrationRejected from "../pages/RegistrationRejected";
import RegistrationPending from "../pages/RegistrationPending";
import OtpScreen from "../pages/OtpScreen";
import UserDetailsPage from "../pages/UserDetailsPage";
import PlanSelection from "../pages/PlanSelection";
import CheckoutScreen from "../pages/CheckoutScreen";
import Error from "../pages/404";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import UseTerms from "../pages/UseTerms";
import ZhRegistrationPage from "../pages/ZhRegistrationPage";
import ZhOtpScreen from "../pages/ZhOtpScreen";
import ZhUserDetailsPage from "../pages/ZhUserDetailsPage";
import ZhRegistrationPending from "../pages/ZhRegistrationPending";
import ZhPlanSelection from "../pages/ZhPlanSelection";
import StaticPlanScreen from "../pages/StaticPlanScreen";

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <RegistrationPage />
        </Route>
        <Route exact path="/zh/registration/phoneNumber">
          <ZhRegistrationPage />
        </Route>
        <Route exact path="/zh/registration/otp">
          <ZhOtpScreen />
        </Route>
        <Route exact path="/zh/registration">
          <ZhUserDetailsPage />
        </Route>
        <Route exact path="/zh/registration/pending">
          <ZhRegistrationPending />
        </Route>
        <Route exact path="/zh/selectMembership">
          <ZhPlanSelection />
        </Route>
        <Route exact path="/zh/planSelection">
          <StaticPlanScreen />
        </Route>
        <Route exact path="/registration/phoneNumber">
          <RegistrationPage />
        </Route>
        <Route exact path="/registration/otp">
          <OtpScreen />
        </Route>
        <Route exact path="/registration">
          <UserDetailsPage />
        </Route>

        <Route exact path="/registration/pending">
          <RegistrationPending />
        </Route>
        <Route exact path="/registration/rejected">
          <RegistrationRejected />
        </Route>
        <Route exact path="/privacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/useTerms">
          <UseTerms />
        </Route>
        <Route exact path="/selectMembership">
          <PlanSelection />
        </Route>
        <Route exact path="/planSelection">
          <StaticPlanScreen />
        </Route>
        <Route exact path="/checkout">
          <CheckoutScreen />
        </Route>
        <Route path="/404">
          <Error />
        </Route>
        <Route component={Error} />
      </Switch>
    </Router>
  );
};

export default MainRouter;
