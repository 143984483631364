import { GET_USER_PROFILE, IS_LOGIN, IS_SIGNUP, USER_STATUS } from "./Types";
import axios from "axios";
// import { AccordionActions } from "@material-ui/core";
var token;

export const getOtp =
  (phoneNumber, history, setSubmitting, from) => (dispatch) => {
    setSubmitting(true);
    return axios
      .post("https://admin.cloudflog.com/api/auth/send_otp/", phoneNumber, {
        // headers: {'Content-Type': 'multipart/form-data'},
      })
      .then((res) => {
        setSubmitting(false);
        console.log(res);
        if (res.data.status === true) {
          if (from == "zh") {
            history.push("/zh/registration/otp");
          } else {
            history.push("/registration/otp");
          }

          if (res.data.detail === "Login OTP sent successfully") {
            dispatch({ type: IS_LOGIN });
          } else if (res.data.detail === "OTP sent successfully") {
            dispatch({ type: IS_SIGNUP });
          }
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        // actions.setSubmitting(false);
      });
  };

export const validateOtp =
  (bodyFormData, setSubmitting, history, isLogin, from) => (dispatch) => {
    setSubmitting(true);
    return axios
      .post("https://admin.cloudflog.com/api/auth/validateOtp/", bodyFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          console.log("isLogin", res.data);

          // localStorage.setItem("email", res.data.email);
          isLogin && localStorage.setItem("accessToken", res.data.access_token);
          setSubmitting(false);
          if (isLogin) {
            localStorage.setItem("email", res.data?.email);
            localStorage.setItem("phoneNumber", res.data?.mobile_number);
          }

          // check res.data?.user_approval_status :"Pending" /"Approved"/ "Rejected"
          if (res?.data?.user_approval_status === "Pending") {
            history.push("/registration/pending");
          } else if (res?.data?.user_approval_status === "Rejected") {
            history.push("/registration/rejected");
          } else {
            if (isLogin) {
              dispatch(getUserStatus());
            }
            isLogin
              ? from == "zh"
                ? history.push("/zh/selectMembership")
                : history.push("/selectMembership")
              : from == "zh"
              ? history.push("/zh/registration")
              : history.push("/registration");
          }
        } else {
          setSubmitting(false);
          alert(res?.data?.detail ?? "Something went wrong");
          // {"status":false,"detail":"First proceed via sending otp request."}
          // {"status":false,"detail":"login otp is incorrect."}
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        console.log(err.response);
      });
  };

export const getUserStatus = (setIsLoading) => async (dispatch) => {
  let token = localStorage.getItem("accessToken");
  return axios
    .get("https://admin.cloudflog.com/api/user/UserStatus/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      // console.log(res);
      console.log("status onFreeTrailPopup ", res.data);
      dispatch({
        type: USER_STATUS,
        payload: res.data.role,
        is_free: res.data.is_free_trial_used,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createUser =
  (regiFormData, history, setError, setIsLoading, from) => (dispatch) => {
    setIsLoading(true);
    return axios
      .post(
        "https://admin.cloudflog.com/api/auth/userRegister/",
        regiFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (Object.keys(res.data) === 1 && res.data.msg) {
          return setError(res.data.msg);
        }
        console.log("createUser", res);
        if (res.data.access_token) {
          setIsLoading(false);
          console.log(res.data.access_token);
          localStorage.setItem("accessToken", res.data.access_token);
          axios
            .get("https://admin.cloudflog.com/api/auth/getRoleList/", {
              headers: {
                Authorization: `Bearer ${res.data.access_token}`,
              },
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
          //history.push("/checkout");
          if (from == "zh") {
            history.push("/zh/registration/pending");
          } else {
            history.push("/registration/pending");
          }
        } else {
          setIsLoading(false);
          alert(res.data?.detail);
          if (from == "zh") {
            history.push("/zh/registration/phoneNumber");
          } else {
            history.push("/registration/phoneNumber");
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("access Data Token>>>", err.response.data.mobile_number[0]);
        alert(err.response.data.mobile_number[0]);
        if (err.response.status == 400) {
          return setError(
            err.response.data.msg || err.response.data.username[0]
          );
        }
        console.log(err);
        // alert(err);
        // actions.setSubmitting(false);
      });
  };
