import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LandingPageAppBar from "../components/LandingPageAppBar";
import { useHistory } from "react-router-dom";
import { Modal, Paper } from "@material-ui/core";
import successIcon from "../assets/images/success-icon.png";
import { Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { registerFreeTrial, onPaypalSuccess } from "../actions/payment";
import { PayPalButtons } from "@paypal/react-paypal-js";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { getPlanDetails } from "../actions/payment";
import MembershipCard from "../components/reusableComponents/MembershipCard";
import Android from "../assets/images/Android.png";
import apple from "../assets/images/apple.png";
import google from "../assets/images/google.png";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getUserStatus } from "../actions/user";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
      overflow: "hidden",
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "30px",
    color: theme.palette.primary.main,
    padding: "0 20%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw",
      lineHeight: "50px",
      padding: "0 10%",
    },
    textAlign: "center",
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.primary.main,
    lineHeight: "130%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "120%",
      lineHeight: "200%",
    },
    textAlign: "center",
  },
  membershipCardContainer: {
    width: "100vw",
    alignItems: "center",

    justifyContent: "space-evenly",
  },
  membershipCard: {
    alignItems: "center",
  },
  successModal: {
    width: "50%",
    margin: "auto",
    marginTop: "10%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  successPaper: {
    padding: "5% 20%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 5%",
    },
  },
  button: {
    width: "150px",
    margin: 5,
  },
  button1: {
    width: "150px",
    margin: 4,
    height: 47,
  },
  ButtonView: {
    alignSelf: "center",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30%",
    paddingRight: "30%",
  },
}));

const CheckoutForm = ({
  selectedPlan,
  setSelectedPlan,
  selectedIndex,
  setSelectedIndex,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  // const clientSecret =
  //   "sk_test_51J3GoxG0K8uc5RJb9oQUKjpsQfcAKjpUyp9htBW7kV99JS1WE88JMvJe7kVMWT0GhsPDboSlXvZfvnbbmtZ8Ucvz00chS8KNa0";
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        plan: "six_month",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://admin.cloudflog.com/api/payments/create-intent/",
        requestOptions
      );

      const data = await res.json();
      console.log("Client Secret:", data.client_secret); // Log client_secret
      const clientSecret = data.client_secret;

      if (!clientSecret) {
        throw new Error("Client secret is missing from the server response.");
      }
      console.log("get Error:::", elements, clientSecret);
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: "http://localhost:3000/404",
        },
        redirect: "always",
      });
      setSelectedPlan("");
      setSelectedIndex("");
      if (error) {
        setSelectedPlan("");
        setSelectedIndex("");
        setErrorMessage(error.message);
      }
    } catch (error) {
      setErrorMessage("Failed to create payment intent: " + error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        style={{ marginTop: "20px" }}
        disabled={!stripe || !elements}
      >
        Pay
      </button>
    </form>
  );
};

export default function Pricing() {
  const classes = useStyles();
  const history = useHistory();
  const [successModal, setSuccessModal] = React.useState(false);
  const store = useSelector((state) => state);
  const { planDetails } = useSelector((state) => state?.PaymentsReducer);
  const { status } = useSelector((state) => state?.userReducer);
  // const { user } = store;
  const dispatch = useDispatch();
  const [year, setYear] = React.useState(true);
  const [months, setMonths] = React.useState(false);
  const [months1, setMonths1] = React.useState(false);
  const [planData, setPlanData] = React.useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");

  useEffect(() => {
    if (planDetails) {
      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 365 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    }
  }, [planDetails]);

  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phoneNumber");
  const isLogin = store.userReducer.isLogin;
  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };
  React.useEffect(() => {
    sessionStorage.getItem("validSession")
      ? sessionStorage.getItem("validSession")
      : history.push("/404");
  }, []);

  React.useEffect(() => {
    dispatch(getUserStatus());
    dispatch(getPlanDetails());
  }, [dispatch]);

  const handleSubmit = (price) => {
    console.log("plane selection", price);
    localStorage.setItem("Plan", price);
    if (price === "0") {
      var bodyFormData = new FormData();
      // bodyFormData.append('email', email);
      bodyFormData.append("mobile_number", phone);

      dispatch(registerFreeTrial(bodyFormData, toggleSuccessModal));
    } else {
      // isLogin ? history.push("/checkout") : history.push("/registration");
      history.push("/checkout");
    }
  };
  // const stripePromise = loadStripe(
  //   "pk_test_51J3GoxG0K8uc5RJbZdanSMiNVUJnTt0EtVXLEiI5Oj7p4b4qNaju4ewFwyFn4lkUWEvNsR7Tgfvh4X2KwiTC7f9E00sjLC2TyJ"
  // );

  const stripePromise = loadStripe(
    "pk_live_51J3GoxG0K8uc5RJb3Gg6klORKh2XxD9wtQcvROlWqqtp5Fc4wjdIFTKT56MVUtsUZnttU5oH8clsrvnPHEe53aqr00R2E7KP11"
  );

  const options = {
    mode: "payment",
    amount: 250,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const options1 = {
    mode: "payment",
    amount: 400,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  // const paypal_client = {
  //   sandbox:
  //     "AR1gb8BGYHFS_y44ohuOglKOKDfj2fYcnTiO9LP4G7c5UxZVVdUWZvSNXPA24cq-RL1ReRuvmwO0mlZL",
  //   production:
  //     "AT8949VZLn8thBLA7v_awK7EekdGUXKgjXrG0RrSQmAbTaIAFhF-yRPAQmhr-XrTmSWkBgm6ipzyWpz4",
  // };

  const onSuccessPayment = (payment, price) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment, price);
    // alert('Payment Done', JSON.stringify(payment));
    const body = new Object();
    body.amount = parseInt(price);
    body.plan = "month";
    body.payment_data = payment;

    dispatch(onPaypalSuccess(body, toggleSuccessModal));
    // localStorage.setItem('Plan', price);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onCancelPayment = (data, price) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", JSON.stringify(data), price);
    // alert('Payment Canceled', data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onErrorPayment = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err);
    // alert('Payment Error', JSON.stringify(err));
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  const handleModalClose = () => {
    toggleSuccessModal();
    history.push("/");
  };

  const newUserPlans = [
    {
      title: "6 Months",
      // subheader: 'Most popular',
      price: "250",
      description: [
        "Unlimited Post",
        "Chat with Anyone",
        "Manage Product",
        "Contact With Admin",
      ],
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
    {
      title: "1 Year",
      // subheader: 'Most popular',
      price: "400",
      description: [
        "Unlimited Post",
        "Chat with Anyone",
        "Manage Product",
        "Contact With Admin",
      ],
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
  ];

  const newUserPlans1 = [
    {
      title: "3 Months",
      // subheader: 'Most popular',
      price: "150",
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
    {
      title: "1 Year",
      // subheader: 'Most popular',
      price: "480",
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
  ];

  const existingUserPlans = [
    {
      title: "Premium",
      // subheader: 'Most popular',
      price: "10",
      description: [
        "20 users included",
        "10 GB of storage",
        "Help center access",
        "Priority email support",
      ],
      buttonText: "Proceed",
      buttonVariant: "contained",
    },
  ];

  const getPlanData = (from) => {
    if (from == "year") {
      setMonths1(false);
      setMonths(false);
      setYear(true);
      let item = [];

      planDetails.map((data) => {
        if (data.plan_validity_in_days == 365 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    } else if (from == "month") {
      setMonths1(false);
      setMonths(true);
      setYear(false);

      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 180 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    } else if (from == "month1") {
      setMonths1(true);
      setMonths(false);
      setYear(false);
      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 90 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      <LandingPageAppBar registration={true} />
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Grid item>
          {/* {status != "Observer" ? (
            <>
              {console.log("Get Value of status", status)}
              <Typography className={classes.mainText} gutterBottom>
                {`You are already a member on the Free Trial of TheWatchCloud. We
            registered you as a member using your email-id ${email} and phone
            number ${phone}. We are in process of defining the pricing structure
            and shall inform you once it is ready. Till then, enjoy the Free
            Trial by downloading our app.`}
              </Typography>
              <Typography className={classes.mainText} gutterBottom>
                {`we wish you Happy Trading !`}
              </Typography>
            </>
          ) : (
            <> */}
          <Typography className={classes.mainText} gutterBottom>
            {`Thank You for Registering with TheWatchCloud!`}
          </Typography>
          <Typography className={classes.mainText} gutterBottom>
            {`
We’re excited to welcome you to TheWatchCloud! You have been registered for a free trial using your email ${email} and phone number ${phone}.
We are currently finalizing our subscription plans and will notify you once they are available. In the meantime, enjoy full access to our platform by downloading the app. Please note that after one month from your registration date, the WhatsApp Group Search feature will be available only with a paid subscription.
`}
          </Typography>
          {/* </>
          )} */}
          <Typography className={classes.mainText} gutterBottom>
            <a href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206">
              <Image src={apple} className={classes.button} />
            </a>
            {/* <br /> */}
            <a href="https://play.google.com/store/apps/details?id=com.cloudflog">
              <Image src={google} className={classes.button} />
            </a>
          </Typography>
        </Grid>
        <CardActions style={{ justifyContent: "center", flexDirection: "row" }}>
          {newUserPlans1.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={5}
              style={{ marginRight: "5px" }}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      / {tier.title}
                    </Typography>
                  </div>
                  {tier.title == "1 Year" ? (
                    <Typography variant="h6" color="textSecondary">
                      Subscribe to Annual Premium Plan with 20% Discount
                    </Typography>
                  ) : (
                    <Typography variant="h6" color="textSecondary">
                      {"Subscribe to Three Months Plan"}
                      <Typography variant="h6" color="textSecondary">
                        &nbsp;
                      </Typography>
                    </Typography>
                  )}
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  {tier.title == "3 Months" ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() =>
                        window.open("https://buy.stripe.com/cN26qw12Fbh4f04148")
                      }
                    >
                      {tier.buttonText}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() =>
                        window.open("https://buy.stripe.com/6oEg166mZbh47xCdQT")
                      }
                    >
                      {tier.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </CardActions>
        {/* <CardActions style={{ justifyContent: "center", flexDirection: "row" }}>
          <Button
            variant="contained"
            className={classes.button}
            color={year ? "primary" : "#fff"}
            onClick={() => getPlanData("year")}
          >
            {"1 Year"}
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            color={months ? "primary" : "#fff"}
            onClick={() => getPlanData("month")}
          >
            {"6 Month"}
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            color={months1 ? "primary" : "#fff"}
            onClick={() => getPlanData("month1")}
          >
            {"3 Month"}
          </Button>
        </CardActions> */}
        {/* <Typography className={classes.captionText}>Plans for existing users coming soon!</Typography> */}
      </Container>
      {/* End hero unit */}
      {/* <Container maxWidth="md" component="main"> */}
      {/* {store.userReducer.isLogin ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: "40px" }}
        >
          {newUserPlans.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={5}
              style={{ marginRight: "5px" }}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      / {tier.title}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  {selectedPlan === "" ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() => {
                        setSelectedPlan(tier.price);
                        setSelectedIndex(index);
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  ) : selectedIndex == index ? (
                    <Elements
                      stripe={stripePromise}
                      options={selectedPlan == "250" ? options : options1}
                    >
                      <CheckoutForm
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                      />
                    </Elements>
                  ) : (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() => {
                        setSelectedPlan(tier.price);
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
          {/* {planData.map((data) => {
            console.log("Get Plan Details:::", data);
            return (
              <Grid
                item
                className={classes.membershipCard}
                style={{ margin: "10px 0px" }}
              >
                <MembershipCard
                  cardTitle={data.plan_name}
                  cardAmount={`USD  ${data.plan_price} `}
                  amountPeriod={`${
                    data.plan_validity_in_days ? data.plan_validity_in_days : 0
                  } days`}
                  cardCaption={data.description}
                  feature1={data.features}
                  // feature5='Lorem ipsum dolor sit amet'
                />
              </Grid>
            );
          })} 
        </Grid>
      ) : (
        // <Grid container spacing={5} alignItems="flex-end">
        //   {existingUserPlans.map((tier) => (
        //     // Enterprise card is full width at sm breakpoint
        //     <Grid item key={tier.title} xs={12} sm={6}>
        //       <Card>
        //         <CardHeader
        //           title={tier.title}
        //           subheader={tier.subheader}
        //           titleTypographyProps={{ align: "center" }}
        //           subheaderTypographyProps={{ align: "center" }}
        //           action={tier.title === "Pro" ? <StarIcon /> : null}
        //           className={classes.cardHeader}
        //         />
        //         <CardContent>
        //           <div className={classes.cardPricing}>
        //             <Typography
        //               component="h2"
        //               variant="h3"
        //               color="textPrimary"
        //             >
        //               ${tier.price}
        //             </Typography>
        //             <Typography variant="h6" color="textSecondary">
        //               /year
        //             </Typography>
        //           </div>
        //           {/* <ul>
        //         {tier.description.map((line) => (
        //           <Typography component="li" variant="subtitle1" align="center" key={line}>
        //             {line}
        //           </Typography>
        //         ))}
        //       </ul> 
        //         </CardContent>
        //         <CardActions style={{ justifyContent: "center" }}>
        //           {tier.price === "0" ? (
        //             <Button
        //               fullWidth
        //               variant={tier.buttonVariant}
        //               color="primary"
        //               onClick={() => handleSubmit(tier.price)}
        //             >
        //               {tier.buttonText}
        //             </Button>
        //           ) : (
        //             <PaypalExpressBtn
        //               env={"production"}
        //               client={paypal_client}
        //               currency={"USD"}
        //               total={tier.price}
        //               onError={onErrorPayment}
        //               onSuccess={(payment) =>
        //                 onSuccessPayment(payment, tier.price)
        //               }
        //               onCancel={(data) => onCancelPayment(data, tier.price)}
        //               shipping={1}
        //               style={{ label: "pay" }}
        //             />
        //           )}
        //           {/* <PayPalButtons style={{ color: 'blue' }} />
        //         </CardActions>
        //       </Card>
        //     </Grid>
        //   ))}
        // </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: "40px" }}
        >
          {newUserPlans.map((tier, index) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={5}
              style={{ marginRight: "5px" }}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      / {tier.title}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  {selectedPlan === "" ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() => {
                        setSelectedPlan(tier.price);
                        setSelectedIndex(index);
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  ) : selectedIndex == index ? (
                    <Elements
                      stripe={stripePromise}
                      options={selectedPlan == "250" ? options : options1}
                    >
                      <CheckoutForm
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                      />
                    </Elements>
                  ) : (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={() => {
                        setSelectedPlan(tier.price);
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )} */}
      {/* </Container> */}
      <Modal
        open={successModal}
        onClose={handleModalClose}
        className={classes.successModal}
      >
        <Paper className={classes.successPaper}>
          <Image src={successIcon} />
          <Typography className={classes.mainText}>
            Welcome to CloudFlog!
          </Typography>
          <Typography style={{ margin: "5% 0" }}>
            App Link:{" "}
            <a href="https://play.google.com/store/apps/details?id=com.cloudflog">
              https://play.google.com/store/apps/details?id=com.cloudflog
            </a>{" "}
          </Typography>
          {/* <Button className={classes.button} href={}>
                Ok
              </Button> */}
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
